<template>
    <div class="cooking-total">
        <section class="cooking-total__settings">
            <h2 class="sr-only">Настройки отчета "Время. Кухня"</h2>
            <form class="cooking__settings" @submit.prevent>
                <div class="cooking-total__dateranges">
                    <daterange-picker class="cooking-total__daterange"
                        v-model="reportDaterangeModel"
                        name="reportDaterange"
                    >
                        Период отчета:
                    </daterange-picker>
                    <daterange-picker class="cooking-total__daterange"
                        v-if="modeId === 'compareOtherPeriod'"
                        v-model="compareDaterangeModel"
                        name="compareOtherPeriod"
                    >
                        Период сравнения:
                    </daterange-picker>
                </div>
                <div class="cooking-total__toggles">
                    <toggle-button class="cooking-total__toggle-input"
                        :color="toggleColors"
                        v-model="modeModel"
                        :width="130"
                        :labels="modeToggleLabels"
                    />
                    <toggle-button class="cooking-total__toggle-input"
                        :color="toggleColors"
                        v-model="durationTypeModel"
                        :width="80"
                        :labels="durationTypeToggleLabels"
                    />
                    <toggle-button class="cooking-total__toggle-input"
                        :color="toggleColors"
                        v-model="viewModel"
                        :width="80"
                        :labels="viewToggleLabels"
                    />
                </div>
                <div class="cooking-total__selects">
                    <select class="cooking-total__select"
                        v-if="viewId === 'chart' && reportTotalDepartments"
                        v-model="chartDepartmentModel"
                    >
                        <option class="cooking-total__option"
                            v-for="department in reportTotalDepartments"
                            :key="department.id"
                            :value="department.id"
                        >
                            {{department.title}}
                        </option>
                    </select>
                    <select class="cooking-total__select"
                        v-model="serviceTypeModel"
                    >
                        <option class="cooking-total__option"
                            v-for="serviceType in serviceTypes"
                            :key="serviceType.id"
                            :value="serviceType.id"
                        >
                            {{serviceType.title}}
                        </option>
                    </select>
                </div>
            </form>
        </section>
        <spinner class="cooking-total__spinner" v-if="status === 'loading'" />
        <error-message class="cooking-total__error-message" v-else-if="status === 'error'">
            Не удалось загрузить данные отчета "Время. Кухня".
        </error-message>
        <section class="cooking-total__data" v-if="status === 'success'">
            <h2 class="sr-only">Данные отчета "Время. Кухня"</h2>
            <div class="cooking-total__base" v-if="modeId === 'base'">
                <div class="cooking-total__box box" v-if="viewId === 'table'">
                    <table class="cooking-total__table cooking-total__table_striped cooking-total__table_hover">
                        <colgroup>
                            <col class="cooking-total__table-column cooking-total__table-column_title">
                            <template v-for="department in reportTotalDepartments">
                                <col :class="`cooking-total__table-column cooking-total__table-column_department cooking-total__table-column_${department.id}`"
                                    span="2"
                                    :key="department.id"
                                >
                            </template>
                        </colgroup>
                        <thead>
                            <tr class="cooking-total__table-row cooking-total__table-row_head">
                                <th class="cooking-total__table-cell cooking-total__table-cell_head cooking-total__table-cell_sortable"
                                    scope="col"
                                    @click="setSorting('title')"
                                >
                                    <div class="cooking-total__table-cell-content">
                                        <span class="cooking-total__table-value">Источник</span>
                                        <svg-icon class="cooking-total__table-value cooking-total__table-value_icon"
                                            v-if="sorting.total.field === 'title' && sorting.direction === 'ASC'"
                                            icon="chevron-up"
                                        />
                                        <svg-icon class="cooking-total__table-value cooking-total__table-value_icon"
                                            v-if="sorting.total.field === 'title' && sorting.direction === 'DESC'"
                                            icon="chevron-down"
                                        />
                                    </div>
                                </th>
                                <template v-for="department in reportTotalDepartments">
                                    <th class="cooking-total__table-cell cooking-total__table-cell_head cooking-total__table-cell_tar cooking-total__table-cell_sortable"
                                        scope="col"
                                        :key="department.id"
                                        @click="setSorting(department.id)"
                                    >
                                        <div class="cooking-total__table-cell-content">
                                            <span class="cooking-total__table-value">{{department.title}}</span>
                                            <svg-icon class="cooking-total__table-value cooking-total__table-value_icon"
                                                v-if="sorting.total.field === department.id && sorting.direction === 'ASC'"
                                                icon="chevron-up"
                                            />
                                            <svg-icon class="cooking-total__table-value cooking-total__table-value_icon"
                                                v-if="sorting.total.field === department.id && sorting.direction === 'DESC'"
                                                icon="chevron-down"
                                            />
                                        </div>
                                    </th>
                                </template>
                            </tr>
                        </thead>
                        <tbody>
                            <tr class="cooking-total__table-row cooking-total__table-row_body cooking-total__table-row_pinned"
                                v-if="reportRestaurants.length > 1"
                            >
                                <td class="cooking-total__table-cell cooking-total__table-cell_body cooking-total__table-cell_title">
                                    <router-link class="link link_red"
                                        :to="{ name: 'CookingRestaurant', params: { id: 'total' } }"
                                        :title="`Смотреть общее время кухни`"
                                    >
                                        Всего
                                    </router-link>
                                </td>
                                <base-total-cell
                                    v-for="department in reportTotalDepartments"
                                    :key="department.id"
                                    :department="department"
                                    :serviceTypeId="serviceTypeId"
                                    :durationTypeId="durationTypeId"
                                />
                            </tr>
                            <tr class="cooking-total__table-row cooking-total__table-row_body"
                                v-for="restaurant in reportRestaurants"
                                :key="restaurant.id"
                            >
                                <td class="cooking-total__table-cell cooking-total__table-cell_title">
                                    <router-link class="link link_red"
                                        :to="{ name: 'CookingRestaurant', params: { id: restaurant.id } }"
                                        :title='`Смотреть время кухни ресторана "${restaurant.title}"`'>
                                        {{restaurant.title}}
                                    </router-link>
                                </td>
                                <base-restaurant-cell
                                    v-for="department in reportTotalDepartments"
                                    :key="department.id"
                                    :restaurant="restaurant"
                                    :compareDepartment="department"
                                    :serviceTypeId="serviceTypeId"
                                    :durationTypeId="durationTypeId"
                                />
                            </tr>
                        </tbody>
                    </table>
                </div>
                <div class="cooking-total__base-chart"
                    v-else-if="viewId === 'chart'"
                >
                    <div class="cooking-total__box cooking-total__box_chart box">
                        <highcharts class="cooking-detail__chart" :options="cookingColumnChartOptions" :key="'cookingBaseColumnChart'" />
                    </div>
                </div>
            </div>
            <div class="cooking-total__compare-other-period" v-else-if="modeId === 'compareOtherPeriod'">
                <p class="cooking-total__error" v-if="!compareCooking">Выберите период сравнения.</p>
                <template v-else>
                    <template v-if="viewId === 'table'">
                        <div class="cooking-total__box box">
                            <table class="cooking-total__table cooking-total__table_striped cooking-total__table_hover">
                                <caption class="cooking-total__table-caption">Всего</caption>
                                <colgroup>
                                    <col class="cooking-total__table-column cooking-total__table-column_title">
                                    <template v-for="department in reportTotalDepartments">
                                        <col :class="`cooking-total__table-column cooking-total__table-column_department cooking-total__table-column_${department.id}`"
                                            span="2"
                                            :key="department.id"
                                        >
                                    </template>
                                </colgroup>
                                <thead>
                                    <tr class="cooking-total__table-row cooking-total__table-row_head">
                                        <th class="cooking-total__table-cell cooking-total__table-cell_head" scope="col">
                                            <span class="cooking-total__table-value">Источник</span>
                                        </th>
                                        <template v-for="department in reportTotalDepartments">
                                            <th class="cooking-total__table-cell cooking-total__table-cell_head cooking-total__table-cell_tar"
                                                scope="col"
                                                :key="department.id"
                                            >
                                                <span class="cooking-total__table-value">{{department.title}}</span>
                                            </th>
                                        </template>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr class="cooking-total__table-row cooking-total__table-row_body">
                                        <td class="cooking-total__table-cell cooking-total__table-cell_body cooking-total__table-cell_title">
                                            {{reportDaterange | daterangeText}}
                                        </td>
                                        <compare-total-primary-cell
                                            v-for="department in reportTotalDepartments"
                                            :key="department.id"
                                            :department="department"
                                            :compareTotal="compareTotal"
                                            :serviceTypeId="serviceTypeId"
                                            :durationTypeId="durationTypeId"
                                        />
                                    </tr>
                                    <tr class="cooking-total__table-row cooking-total__table-row_body">
                                        <td class="cooking-total__table-cell cooking-total__table-cell_body cooking-total__table-cell_title">
                                            {{compareDaterange | daterangeText}}
                                        </td>
                                        <compare-total-compare-cell
                                            v-for="department in reportTotalDepartments"
                                            :key="department.id"
                                            :department="department"
                                            :compareTotal="compareTotal"
                                            :serviceTypeId="serviceTypeId"
                                            :durationTypeId="durationTypeId"
                                        />
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                        <div class="cooking-total__box box"
                            v-for="restaurant in reportRestaurants"
                            :key="restaurant.id"
                        >
                            <table class="cooking-total__table cooking-total__table_striped cooking-total__table_hover">
                                <caption class="cooking-total__table-caption">
                                    <router-link class="link link_red"
                                        :to="{ name: 'CookingRestaurant', params: { id: restaurant.id } }"
                                        :title="`Смотреть время кухни ресторана ${restaurant.title}`"
                                    >
                                        {{restaurant.title}}
                                    </router-link>
                                </caption>
                                <colgroup>
                                    <col class="cooking-total__table-column cooking-total__table-column_title">
                                    <template v-for="department in restaurant.departments">
                                        <col class="cooking-total__table-column cooking-total__table-column_department" :key="department.id">
                                    </template>
                                </colgroup>
                                <thead>
                                    <tr class="cooking-total__table-row cooking-total__table-row_head">
                                        <th class="cooking-total__table-cell cooking-total__table-cell_head" scope="col" rowspan="2">
                                            <span class="cooking-total__table-value">Источник</span>
                                        </th>
                                        <template v-for="department in reportTotalDepartments">
                                            <th class="cooking-total__table-cell cooking-total__table-cell_head cooking-total__table-cell_tar" scope="col" :key="department.id">
                                                <span class="cooking-total__table-value">{{department.title}}</span>
                                            </th>
                                        </template>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr class="cooking-total__table-row cooking-total__table-row_body">
                                        <td class="cooking-total__table-cell cooking-total__table-cell_body cooking-total__table-cell_title">
                                            {{reportDaterange | daterangeText}}
                                        </td>
                                        <compare-restaurant-primary-cell
                                            v-for="totalDepartment in reportTotalDepartments"
                                            :key="totalDepartment.id"
                                            :restaurant="restaurant"
                                            :totalDepartment="totalDepartment"
                                            :compareCooking="compareCooking"
                                            :serviceTypeId="serviceTypeId"
                                            :durationTypeId="durationTypeId"
                                        />
                                    </tr>
                                    <tr class="cooking-total__table-row cooking-total__table-row_body">
                                        <td class="cooking-total__table-cell cooking-total__table-cell_body cooking-total__table-cell_title">
                                            {{compareDaterange | daterangeText}}
                                        </td>
                                        <compare-restaurant-compare-cell
                                            v-for="totalDepartment in reportTotalDepartments"
                                            :key="totalDepartment.id"
                                            :restaurant="restaurant"
                                            :totalDepartments="reportTotalDepartments"
                                            :totalDepartment="totalDepartment"
                                            :compareCooking="compareCooking"
                                            :serviceTypeId="serviceTypeId"
                                            :durationTypeId="durationTypeId"
                                        />
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    </template>
                    <div class="cooking-total__base-chart"
                        v-else-if="viewId === 'chart'"
                    >
                        <div class="cooking-total__box cooking-total__box_chart box">
                            <highcharts class="cooking-detail__chart" :options="cookingColumnChartOptions" :key="'cookingCompareColumnChart'" />
                        </div>
                    </div>
                </template>
            </div>
        </section>
    </div>
</template>

<script>
    import { mapActions, mapMutations, mapState } from "vuex";
    import { daterangeKey, isDaterangeSet, daterangeText } from "@/helpers/daterange";
    import { compareStrings, compareNumbers } from "@/helpers/compare";
    import { durationFormat } from "@/helpers/filters";
    import DaterangePicker from "@/components/DaterangePicker";
    import BaseTotalCell from "./cooking-total/BaseTotalCell";
    import BaseRestaurantCell from "./cooking-total/BaseRestaurantCell";
    import CompareTotalPrimaryCell from "./cooking-total/CompareTotalPrimaryCell";
    import CompareTotalCompareCell from "./cooking-total/CompareTotalCompareCell";
    import CompareRestaurantPrimaryCell from "./cooking-total/CompareRestaurantPrimaryCell";
    import CompareRestaurantCompareCell from "./cooking-total/CompareRestaurantCompareCell";

    export default{
        name: "CookingTotal",
        components: {
            DaterangePicker,
            BaseTotalCell,
            BaseRestaurantCell,
            CompareTotalPrimaryCell,
            CompareTotalCompareCell,
            CompareRestaurantPrimaryCell,
            CompareRestaurantCompareCell,
        },
        props: {
            reportDaterange: {
                type: Object,
                required: true
            },
            compareDaterange: {
                required: true
            },
            userRestaurants: {
                required: true
            },
            serviceTypes: {
                type: Array,
                required: true
            }
        },
        data() {
            return {
                status: "loading"
            };
        },
        computed: {
            ...mapState({
                modeId: state => state.cooking.modeId,
                viewId: state => state.cooking.viewId,
                serviceTypeId: state => state.cooking.serviceTypeId,
                durationTypeId: state => state.cooking.durationTypeId,
                chartDepartmentId: state => state.cooking.chartDepartmentId,
                sorting: state => state.cooking.sorting,
                data: state => state.cooking.data
            }),
            reportDaterangeModel: {
                get() {
                    return this.reportDaterange;
                },
                set(daterange) {
                    this.setReportDaterange(daterange);
                }
            },
            compareDaterangeModel: {
                get() {
                    return this.compareDaterange;
                },
                set(daterange) {
                    this.setCookingCompareDaterange(daterange);
                }
            },
            modeModel: {
                get() {
                    return this.modeId === "compareOtherPeriod";
                },
                set(isCompareOtherPeriod) {
                    this.setCookingParameter({
                        parameter: "modeId",
                        value: isCompareOtherPeriod ? "compareOtherPeriod" : "base"
                    });
                }
            },
            modeToggleLabels() {
                return {
                    unchecked: "Данные периода",
                    checked: "Сравнение периодов"
                };
            },
            toggleColors() {
                return {
                    unchecked: "#e64d53",
                    checked: "#e0121a"
                };
            },
            viewToggleLabels() {
                return {
                    unchecked: "Таблица",
                    checked: "График"
                };
            },
            viewModel: {
                get() {
                    return this.viewId === "chart";
                },
                set(isChart) {
                    this.setCookingParameter({
                        parameter: "viewId",
                        value: isChart ? "chart" : "table"
                    });
                }
            },
            durationTypeToggleLabels() {
                return {
                    unchecked: "Среднее",
                    checked: "Медиана"
                };
            },
            durationTypeModel: {
                get() {
                    return this.durationTypeId === "median_duration";
                },
                set(isMedian) {
                    this.setCookingParameter({
                        parameter: "durationTypeId",
                        value: isMedian ? "median_duration" : "avg_duration"
                    });
                }
            },
            chartDepartmentModel: {
                get() {
                    return this.chartDepartmentId;
                },
                set(chartDepartmentId) {
                    this.setCookingParameter({
                        parameter: "chartDepartmentId",
                        value: chartDepartmentId
                    });
                }
            },
            serviceTypeModel: {
                get() {
                    return this.serviceTypeId;
                },
                set(serviceTypeId) {
                    this.setCookingParameter({
                        parameter: "serviceTypeId",
                        value: serviceTypeId
                    });
                }
            },
            requiredDateranges() {
                let dateranges = [this.reportDaterange];

                if (this.modeId === "compareOtherPeriod" && isDaterangeSet(this.compareDaterange)) {
                    dateranges.push(this.compareDaterange);
                }

                return dateranges;
            },
            requiredCookings() {
                return this.requiredDateranges.map(daterange => this.data[daterangeKey(daterange)]);
            },
            reportCooking() {
                return this.data?.[daterangeKey(this.reportDaterange)];
            },
            reportRestaurants() {
                return this.reportCooking?.restaurants?.sort((restaurant1, restaurant2) => {
                    if (this.sorting.total.field === "title") {
                        return compareStrings(
                            restaurant1.title,
                            restaurant2.title,
                            this.sorting.direction
                        );
                    }

                    let restaurant1Department = this.findDepartment(restaurant1, this.sorting.total.field);
                    let restaurant2Department = this.findDepartment(restaurant2, this.sorting.total.field);

                    return compareNumbers(
                        restaurant1Department?.[this.serviceTypeId]?.[this.durationTypeId],
                        restaurant2Department?.[this.serviceTypeId]?.[this.durationTypeId],
                        this.sorting.direction
                    );
                });
            },
            reportTotal() {
                return this.reportCooking?.total;
            },
            reportTotalDepartments() {
                return this.reportCooking?.total?.departments;
            },
            compareCooking() {
                if (isDaterangeSet(this.compareDaterange)) {
                    return this.data[daterangeKey(this.compareDaterange)];
                }
            },
            compareTotal() {
                return this.compareCooking?.total;
            },
            cookingColumnChartOptions() {
                let department = this.reportTotalDepartments.find(({id}) => id === this.chartDepartmentId);

                let options = {
                    chart: {
                        type: "column"
                    },
                    colors: ["#e0121a", "#ff5261"],
                    title: {
                        text: null
                    },
                    legend: {
                        enabled: false
                    },
                    xAxis: {
                        categories: []
                    },
                    yAxis: {
                        min: 0,
                        title: {
                            text: "Время"
                        },
                        labels: {
                            formatter() {
                                return durationFormat(this.value);
                            }
                        }
                    },
                    tooltip: {
                        headerFormat: "<b>{point.x}</b><br/>",
                        pointFormatter() {
                            return `${this.series.name}: ${durationFormat(this.y)}`;
                        }
                    },
                    plotOptions: {
                        column: {
                            dataLabels: {
                                enabled: true,
                                formatter() {
                                    return durationFormat(this.y);
                                }
                            }
                        }
                    },
                    series: [{
                        name: null,
                        data: []
                    }]
                };

                this.reportRestaurants?.forEach(reportRestaurant => {
                    options.xAxis.categories.push(reportRestaurant.title);
                    options.series[0].data.push(this.findDepartment(reportRestaurant, department.id)?.[this.serviceTypeId]?.[this.durationTypeId] || null);
                });

                if (this.modeId === "compareOtherPeriod" && this.compareCooking) {
                    options.legend.enabled = true;
                    options.series[0].name = daterangeText(this.reportDaterange);

                    options.series.push({
                        name: daterangeText(this.compareDaterange),
                        data: []
                    });

                    this.reportRestaurants?.forEach(reportRestaurant => {
                        let compareRestaurant = this.findRestaurant(this.compareCooking, reportRestaurant.id);
                        let compareRestaurantDepartment = this.findDepartment(compareRestaurant, department.id);
                        let compareRestaurantDepartmentService = compareRestaurantDepartment?.[this.serviceTypeId];

                        options.series[1].data.push(compareRestaurantDepartmentService?.[this.durationTypeId] || null);
                    });
                }

                return options;
            },
        },
        methods: {
            ...mapMutations([
                "setReportDaterange",
                "setCookingCompareDaterange",
                "setCookingParameter",
                "setCookingSorting"
            ]),
            ...mapActions([
                "requestCookings"
            ]),
            findRestaurant(cooking, restaurantId) {
                return cooking?.restaurants?.find(({ id }) => id === restaurantId);
            },
            findDepartment(restaurant, departmentId) {
                return restaurant?.departments?.find(({ id }) => id === departmentId);
            },
            setSorting(field) {
                this.setCookingSorting({ table: "total", field });
            },
            showReport() {
                this.status = "success";
            },
            loadCookings() {
                this.status = "loading";

                let daterangesWithoutData = this.requiredDateranges.filter(
                    daterange => !Boolean(this.data[daterangeKey(daterange)])
                );

                this.requestCookings(daterangesWithoutData).then(() => {
                    this.showReport();
                }).catch(() => {
                    this.status = "error";
                });
            },
        },
        filters: {
            daterangeText
        },
        created() {
            if (this.userRestaurants?.ids?.length === 1) {
                this.$router.push({
                    name: "CookingRestaurant",
                    params:{
                        id: this.userRestaurants?.ids[0]
                    }
                });
            } else if (this.requiredCookings.every(Boolean)) {
                this.showReport();
            } else {
                this.loadCookings();
            }
        },
        watch: {
            requiredCookings(requiredCookings) {
                if (!requiredCookings.every(Boolean)) {
                    this.loadCookings();
                }
            }
        }
    }
</script>

<style lang="scss">
    .cooking-total__settings {
        margin-bottom: 15px;
    }
    .cooking-total__dateranges {
        display: grid;
        grid-auto-columns: auto;
        grid-auto-rows: auto;
        grid-gap: 10px;
        justify-content: start;
        align-content: start;
        justify-items: start;
        align-items: start;
        margin-bottom: 15px;

        @include desktop {
            grid-auto-flow: column;
        }
    }
    .cooking-total__toggles {
        display: grid;
        grid-auto-columns: auto;
        grid-auto-rows: auto;
        grid-gap: 10px;
        justify-content: start;
        align-content: start;
        justify-items: start;
        align-items: start;

        @include desktop {
            grid-auto-flow: column;
        }
    }
    .cooking-total__selects {
        display: grid;
        grid-auto-columns: auto;
        grid-auto-rows: auto;
        grid-gap: 10px;
        justify-content: start;
        align-content: start;
        justify-items: start;
        align-items: start;

        margin-top: 15px;

        @include desktop {
            grid-auto-flow: column;
        }
    }
    .cooking-total__select {
        min-width: 240px;
        padding: 4px 8px;
        border-color: $gray-line;
        border-radius: 4px;

        &:focus {
            outline: none;
        }
    }
    .cooking-total__box {
        padding: 16px;
        overflow-x: auto;

        &_chart {
            padding: 16px;
        }

        & + & {
            margin-top: 15px;
        }
    }
    .cooking-total__table {
        width: 100%;
    }
    .cooking-total__table-caption {
        padding: 8px;
        font-size: 24px;
        font-weight: bold;
    }
    .cooking-total__table-column {
        width: 16%;

        &_title {
            width: 20%;
        }
    }
    .cooking-total__table-row {
        &_body {
            .cooking-total__table_striped &:nth-of-type(odd) {
                background-color: $background-gray-primary;
            }

            .cooking-total__table_hover &:hover {
                background-color: $gray-form;
            }
        }

        &_pinned {
            background-color: $gray-form !important;
        }
    }
    .cooking-total__table-cell {
        font-size: 18px;
        padding: 12px 8px;

        &_head {
            padding: 8px;
            white-space: nowrap;
        }

        &_sortable {
            cursor: pointer;
        }

        &_title {
            font-weight: bold;
        }

        &_baseline {
            vertical-align: baseline;
        }

        &_small {
            font-size: 16px;
        }

        &_tac {
            text-align: center;
        }

        &_tar {
            text-align: right;
        }
    }
    .cooking-total__table-cell-content {
        display: inline-flex;
        flex-flow: row nowrap;
        justify-content: flex-start;
        align-items: center;
    }
    .cooking-total__table-value {
        display: block;
        line-height: 1;

        &_icon {
            width: 18px;
            height: 18px;
            margin-left: 3px;
        }

        &_positive {
            color: green;
        }

        &_negative {
            color: red;
        }
    }
</style>
