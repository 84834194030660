<template>
    <td class="cooking-total__table-cell cooking-total__table-cell_body cooking-total__table-cell_tar">
        <template v-if="!restaurantDepartment">
            <span class="cooking-total__table-value">Нет цеха</span>
        </template>
        <template v-else-if="!restaurantDepartmentService">
            <span class="cooking-total__table-value">Нет сервиса</span>
        </template>
        <template v-else>
            <span class="cooking-total__table-value">{{restaurantDepartmentServiceDuration | durationFormat}}</span>
            <difference class="cooking-total__table-value"
                v-if="compareRestaurantDepartmentServiceDuration"
                :primaryValue="restaurantDepartmentServiceDuration"
                :compareValue="compareRestaurantDepartmentServiceDuration"
                mode="percentage"
                good="negative"
                filter="percentFloatFormat"
            />
        </template>
    </td>
</template>

<script>
    import Difference from "@/components/Difference";

    export default {
        name: "CompareRestaurantPrimaryCell",
        components: {
            Difference
        },
        props: {
            restaurant: {
                type: Object,
                required: true
            },
            totalDepartment: {
                type: Object,
                required: true
            },
            compareCooking: {
                type: Object,
                required: true
            },
            serviceTypeId: {
                type: String,
                required: true
            },
            durationTypeId: {
                type: String,
                required: true
            },
        },
        computed: {
            restaurantDepartment() {
                return this.restaurant?.departments?.find(({ id }) => id === this.totalDepartment.id);
            },
            restaurantDepartmentService() {
                return this.restaurantDepartment?.[this.serviceTypeId];
            },
            restaurantDepartmentServiceDuration() {
                return this.restaurantDepartmentService?.[this.durationTypeId];
            },
            compareRestaurant() {
                return this.compareCooking?.restaurants?.find(({ id }) => id === this.restaurant.id);
            },
            compareRestaurantDepartment() {
                return this.compareRestaurant?.departments?.find(({ id }) => id === this.totalDepartment.id);
            },
            compareRestaurantDepartmentService() {
                return this.compareRestaurantDepartment?.[this.serviceTypeId];
            },
            compareRestaurantDepartmentServiceDuration() {
                return this.compareRestaurantDepartmentService?.[this.durationTypeId];
            },
        }
    }
</script>
