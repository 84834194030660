<template>
    <td class="cooking-total__table-cell cooking-total__table-cell_body cooking-total__table-cell_tar">
        <template v-if="!restaurantDepartment">Нет цеха</template>
        <template v-else-if="!restaurantDepartmentService">Нет сервиса</template>
        <template v-else>
            <span class="cooking-total__table-value">{{restaurantDepartmentServiceDuration | durationFormat}}</span>
            <difference class="cooking-total__table-value"
                v-if="compareDepartmentServiceDuration"
                :primaryValue="restaurantDepartmentServiceDuration"
                :compareValue="compareDepartmentServiceDuration"
                mode="percentage"
                good="negative"
                filter="percentFloatFormat"
            />
        </template>
    </td>
</template>

<script>
    import Difference from "@/components/Difference";

    export default {
        name: "BaseRestaurantCell",
        components: {
            Difference
        },
        props: {
            restaurant: {
                type: Object,
                required: true
            },
            compareDepartment: {
                type: Object,
                required: true
            },
            serviceTypeId: {
                type: String,
                required: true
            },
            durationTypeId: {
                type: String,
                required: true
            },
        },
        computed: {
            restaurantDepartment() {
                return this.restaurant?.departments?.find(({ id }) => id === this.compareDepartment?.id);
            },
            restaurantDepartmentService() {
                return this.restaurantDepartment?.[this.serviceTypeId];
            },
            restaurantDepartmentServiceDuration() {
                return this.restaurantDepartment?.[this.serviceTypeId]?.[this.durationTypeId];
            },
            compareDepartmentServiceDuration() {
                return this.compareDepartment?.[this.serviceTypeId]?.[this.durationTypeId];
            },
        }
    }
</script>
