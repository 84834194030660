<template>
    <td class="cooking-total__table-cell cooking-total__table-cell_body cooking-total__table-cell_tar">
        <template v-if="!compareRestaurant">
            <span class="cooking-total__table-value">Нет ресторана</span>
        </template>
        <template v-else-if="!compareRestaurantDepartment">
            <span class="cooking-total__table-value">Нет цеха</span>
        </template>
        <template v-else-if="!compareRestaurantDepartmentService">
            <span class="cooking-total__table-value">Нет сервиса</span>
        </template>
        <template v-else>
            <span class="cooking-total__table-value">{{compareRestaurantDepartmentServiceDuration | durationFormat}}</span>
        </template>
    </td>
</template>

<script>
    import Difference from "@/components/Difference";

    export default {
        name: "CompareRestaurantCompareCell",
        components: {
            Difference
        },
        props: {
            restaurant: {
                type: Object,
                required: true
            },
            totalDepartments: {
                type: Array,
                required: true
            },
            totalDepartment: {
                type: Object,
                required: true
            },
            compareCooking: {
                type: Object,
                required: true
            },
            serviceTypeId: {
                type: String,
                required: true
            },
            durationTypeId: {
                type: String,
                required: true
            },
        },
        computed: {
            compareRestaurant() {
                return this.compareCooking?.restaurants?.find(({ id }) => id === this.restaurant.id);
            },
            compareRestaurantDepartment() {
                return this.compareRestaurant?.departments?.find(({ id }) => id === this.totalDepartment.id);
            },
            compareRestaurantDepartmentService() {
                return this.compareRestaurantDepartment?.[this.serviceTypeId];
            },
            compareRestaurantDepartmentServiceDuration() {
                return this.compareRestaurantDepartmentService?.[this.durationTypeId];
            },
        }
    }
</script>
