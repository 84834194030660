<template>
    <td class="cooking-total__table-cell cooking-total__table-cell_body cooking-total__table-cell_tar">
        <span class="cooking-total__table-value">
            <template v-if="departmentService">
                {{departmentServiceDuration | durationFormat}}
            </template>
            <template v-else>Нет сервиса</template>
        </span>
    </td>
</template>

<script>
    export default {
        name: "BaseTotalCell",
        props: {
            department: {
                type: Object,
                required: true
            },
            serviceTypeId: {
                type: String,
                required: true
            },
            durationTypeId: {
                type: String,
                required: true
            },
        },
        computed: {
            departmentService() {
                return this.department?.[this.serviceTypeId];
            },
            departmentServiceDuration() {
                return this.department?.[this.serviceTypeId]?.[this.durationTypeId];
            },
        }
    }
</script>
