<template>
    <td class="cooking-total__table-cell cooking-total__table-cell_body cooking-total__table-cell_tar">
        <span class="cooking-total__table-value">
            <template v-if="!compareDepartment">Нет цеха</template>
            <template v-else-if="!compareDepartmentService">Нет сервиса</template>
            <template v-else>{{compareDepartmentServiceDuration | durationFormat}}</template>
        </span>
    </td>
</template>

<script>
    import Difference from "@/components/Difference";

    export default {
        name: "CompareTotalCompareCell",
        components: {
            Difference
        },
        props: {
            department: {
                type: Object,
                required: true
            },
            compareTotal: {
                type: Object,
                required: true
            },
            serviceTypeId: {
                type: String,
                required: true
            },
            durationTypeId: {
                type: String,
                required: true
            },
        },
        computed: {
            compareDepartment() {
                return this.compareTotal?.departments?.find(({ id }) => id === this.department.id);
            },
            compareDepartmentService() {
                return this.compareDepartment?.[this.serviceTypeId];
            },
            compareDepartmentServiceDuration() {
                return this.compareDepartmentService?.[this.durationTypeId];
            },
        }
    }
</script>
