<template>
    <td class="cooking-total__table-cell cooking-total__table-cell_body cooking-total__table-cell_tar" >
        <template v-if="departmentService">
            <span class="cooking-total__table-value">{{departmentServiceDuration | durationFormat}}</span>
            <difference class="cooking-total__table-value"
                v-if="compareServiceDuration"
                :primaryValue="departmentServiceDuration"
                :compareValue="compareServiceDuration"
                mode="percentage"
                good="negative"
                filter="percentFloatFormat"
            />
        </template>
        <template v-else>
            <span class="cooking-total__table-value">Нет сервиса</span>
        </template>
    </td>
</template>

<script>
    import Difference from "@/components/Difference";

    export default {
        name: "CompareTotalPrimaryCell",
        components: {
            Difference
        },
        props: {
            department: {
                type: Object,
                required: true
            },
            compareTotal: {
                type: Object,
                required: true
            },
            serviceTypeId: {
                type: String,
                required: true
            },
            durationTypeId: {
                type: String,
                required: true
            },
        },
        computed: {
            departmentService() {
                return this.department?.[this.serviceTypeId];
            },
            departmentServiceDuration() {
                return this.department?.[this.serviceTypeId]?.[this.durationTypeId];
            },
            compareServiceDuration() {
                return this.compareTotal?.departments?.find(({ id }) => id === this.department.id)?.[this.serviceTypeId]?.[this.durationTypeId];
            },
        }
    }
</script>
